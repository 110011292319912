import React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import ServicePage from "../../components/service";


export default function WildlifeInventoryPage() {
    const {service} = useStaticQuery(graphql`
        query {
          service: servicesJson(key: {eq: "wildlife-inventory"}) {
            title
            title_long
            thumbnail {
              path {
                childImageSharp {
                  gatsbyImageData(width: 1024)
                }
              }
            }
            projects {
              title
              client
              location
            }
          }
        }
    `)
    return (
        <ServicePage service={service}>
            <p>
                REC ensures thorough and efficient wildlife surveys through well-planned field assessments and desktop studies including:
                <ul className="list-disc pl-8 mt-2">
                    <li>Migratory bird nest surveys</li>
                    <li>Migratory bird nest monitoring pre-construction, during construction and post-construction</li>
                    <li>Migratory bird point counts</li>
                    <li>Wildlife sign and presence surveys</li>
                    <li>Habitat assessments</li>
                    <li>Species-at-risk surveys</li>
                    <li>Remote camera surveys</li>
                    <li>Winter wildlife tracking surveys</li>
                    <li>Ungulate pellet and track counts</li>
                    <li>Ungulate browse use surveys</li>
                    <li>Drone-based imagery (still pictures and video)</li>
                    <li>Aerial surveys including drone-based imagery (still pictures and video)</li>
                    <li>Telemetry</li>
                    <li>Scat collection and analysis</li>
                    <li>Wildlife immobilization and capture</li>
                    <li>Literature review and historical data review</li>
                </ul>
            </p>
        </ServicePage>
    )
}
